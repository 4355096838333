<template>
<div class="wrapper">
  <el-page-header @back="$router.go(-1)" content="老人详情"></el-page-header>
  <div class="m-content">
    <el-collapse v-model="activeNames">
      <el-collapse-item title="基本信息" name="1">
        <div class="mc-item">
          <p>
            <span>姓名：</span>  
            {{ orgDetail.compName }}
          </p>
          <p>
            <span>性别：</span>  
            {{ orgDetail.sigFromDate }}
          </p>
          <p>
            <span>年龄：</span>  
            {{ orgDetail.compAddres }}
          </p>
          <p>
            <span>出生日期：</span>  
            {{ orgDetail.contPerson }}
          </p>
          <p>
            <span>身份证号：</span>  
            {{ orgDetail.contPhone }}
          </p>
        </div>
        
      </el-collapse-item>
      <el-collapse-item title="生活信息" name="2">
        
      </el-collapse-item>
      <el-collapse-item title="残疾信息" name="3">
        
      </el-collapse-item>
      <el-collapse-item title="联系人信息" name="4">
        <div>用户决策：根据场景可给予用户操作建议或安全提示，但不能代替用户进行决策；</div>
        <div>结果可控：用户可以自由的进行操作，包括撤销、回退和终止当前操作等。</div>
      </el-collapse-item>
    </el-collapse>
  </div>
  <!-- 核心服务的表单弹框 -->
  <el-dialog title="核心服务" :visible.sync="showCoreServe" width="35%">
    <el-form ref="coreServeForm" :rules="coreServeRules" :model="coreServeForm" label-width="120px">
      <el-form-item label="服务分类" prop="serviceType">
        <el-select v-model="coreServeForm.serviceType" placeholder="请选择" style="width:100%;">
            <el-option
              v-for="item in serviceTypeList"
              :key="item.dictPidVal"
              :label="item.dictDesc"
              :value="item.dictPidVal">
            </el-option>
          </el-select>
      </el-form-item>
      <el-form-item label="服务项目" prop="serviceName">
        <el-input v-model="coreServeForm.serviceName" autocomplete="off" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="服务内容介绍">
        <el-input type="textarea" :rows="2" v-model="coreServeForm.serviceContent" placeholder="请输入"></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="showCoreServe = false">取 消</el-button>
      <el-button type="primary" @click="coreServeSave('coreServeForm')">确 定</el-button>
    </div>
  </el-dialog>
</div>
</template>

<script>
import {
  mapActions
} from "vuex";

export default {
  name: 'Detail',
  data() {
    return {
      // 分页的参数配置
      tablePage: {
        totalResult: 0,
        currentPage: 1,
        pageSize: 10,
        align: "left",
        pageSizes: [10, 20, 50, 100, 500, 1000],
        perfect: true
      },
      activeNames: '1',
      staffNameList: [],
      orgDetail: {},
      // 核心服务
      showCoreServe: false,
      coreServeList: [],
      coreServeForm: {
        serviceType: '',
        serviceName: '',
        serviceContent: ''
      },
      serviceTypeList: [],
      coreServeRules: {
        serviceType: [
          { required: true, message: '请输入服务分类', trigger: 'blur' }
        ],
        serviceName: [
          { required: true, message: '请输入服务项目', trigger: 'blur' }
        ],
      }
    }
  },
  computed: {
    routeType() {
      return this.$route.params.type
    }
  },
  created() {
    this.initView()
  },
  methods: {
    ...mapActions([
      "getWorkPersonListServe",
      "updateOrgsServe",
      "detailServeOrgsServe",
      "getHomeCoreSeriveListServe",
      "addHomeCoreSeriveServe",
      "delHomeCoreSeriveServe",
      "getSysDictServe",
    ]),
    initView() {
      this.getOrgDetail()
      this.getStaffNameList()
      this.getCoreServeList()
      this.getSysDictList('genService', 'serviceTypeList') //服务分类

    },
    addServe() {
      this.showCoreServe = true
    },
    editCoreServe(itemData) {
      this.showCoreServe = true
      this.coreServeForm = {
        serviceType: itemData.serviceType,
        serviceName: itemData.serviceName,
        serviceContent: itemData.serviceContent,
        id: itemData.id
      }
    },
    delCoreServe(id) {
      this.$XModal.confirm("您确定要删除该数据?").then(type => {
        if (type === "confirm") {
          console.log('执行删除操作');
          this.delete(id)
        }
			})
    },
    delete(id) {
      this.delHomeCoreSeriveServe({
        id: id
      }).then(res => {
        if (res.code == 200) {
          this.$XModal.message({
            message: '删除成功',
            status: "success"
          });
          this.getCoreServeList()
        }
        this.loading = false;
      })
		},
    // 保存核心服务
    coreServeSave(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let params = this.coreServeForm
          params.compId = this.$route.params.id
          this.addHomeCoreSeriveServe(params).then(res => {
            if(res.code == 200) {
              this.$XModal.message({
                message: '添加成功',
                status: "success"
              });
              this.getCoreServeList()
              this.showCoreServe = false
            }
          })
        }
      });
    },
    // 查询企业详情
    getOrgDetail() {
      let params = {
        id: this.$route.params.id
      }
      this.detailServeOrgsServe(params).then(res => {
        if(res.code == 200) {
          this.orgDetail = res.data
        }
      })
    },
    // 查询员工名单
    getStaffNameList() {
      this.getWorkPersonListServe({
        current: this.tablePage.currentPage,
        size: this.tablePage.pageSize,
        param: {
          comp_id: this.$route.params.id
        }
      }).then(res => {
        if (res.code == 200) {
          this.staffNameList = res.data.records;
          this.tablePage.totalResult = +res.data.total;
        }
      })
		},
    // 查询核心服务列表
    getCoreServeList() {
      this.getHomeCoreSeriveListServe({
        current: this.tablePage.currentPage,
        size: this.tablePage.pageSize,
        param: {}
      }).then(res => {
        if (res.code == 200) {
          this.coreServeList = res.data.records;
          this.tablePage.totalResult = +res.data.total;
        }
      })
		},
    // 获取数据字典
    getSysDictList(code, codeList) {
      let params = {
        typeCode: code
      }
      this.getSysDictServe(params).then(res => {
        if(res.code == 200) {
          this[codeList] = res.data
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  padding: 10px;
  overflow: hidden;
  .m-content{
    .mc-item{
      line-height: 30px;
      span{
        display: inline-block;
        width: 100px;
        text-align: right;
      }
    }
  }
  h4 {
    height: 40px;
    line-height: 40px;
    color: #333;
    padding-left: 20px;
    margin-bottom: 20px;
  }
}
</style>
